var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Proceso de selección","toBack":{ name: 'AdministradorDeVacantes' }}},[_c('div',{staticClass:"row px-5 py-3"},[_c('div',{staticClass:"col-12 col-md-10"},[_c('CardTable',{attrs:{"data":_vm.resumenInCards}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('vs-button',{staticClass:"h-100",on:{"click":_vm.openModal}},[_vm._v(" Administrador de elementos ")])],1)]),_c('div',{staticClass:"table-responsive mt-3 px-4"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_seleccion"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Nombres y apellidos")]),_c('th',[_vm._v("Numero de documento")]),_vm._l((_vm.etapas),function(etapa){return _c('th',{key:etapa.id},[_vm._v(" "+_vm._s(etapa.nombre)+" ")])}),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Estado final")]),_c('th',[_vm._v("Evaluar candidatos")])],2)]),_c('tbody',_vm._l((_vm.aspirantes),function(aspirante,index){return _c('tr',{key:aspirante.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(aspirante.nombres + " " + aspirante.apellidos))]),_c('td',[_vm._v(_vm._s(aspirante.numeroDocumento))]),_vm._l((_vm.etapas),function(etapa){return _c('td',{key:etapa.id},[(
                _vm.getEstadoDocumento(etapa.nombre, aspirante.id) == 'Aprobado'
              )?_c('span',[_c('i',{staticClass:"fas fa-check-circle text-success"})]):(
                _vm.getEstadoDocumento(etapa.nombre, aspirante.id) == 'Rechazado'
              )?_c('span',[_c('i',{staticClass:"fas fa-times-circle text-danger"})]):(
                _vm.getEstadoDocumento(etapa.nombre, aspirante.id) == 'Pendiente'
              )?_c('span',[_c('i',{staticClass:"fas fa-clock text-warning"})]):_c('span',[_c('i',{staticClass:"fas fa-clock text-gray"})])])}),_c('td',[_c('b-badge',{attrs:{"variant":_vm.getBadgeStatus(aspirante.seleccionEvaluacion.estado).badge}},[_c('i',{class:_vm.getBadgeStatus(aspirante.seleccionEvaluacion.estado).icon}),_vm._v(" "+_vm._s(aspirante.seleccionEvaluacion.estado)+" ")])],1),_c('td',[_c('RouterLink',{staticClass:"btn btn-danger",attrs:{"to":{
                name: 'EvaluarDocumentosDeSeleccion',
                params: {
                  vacanteId: _vm.$route.params.vacanteId,
                  postulacionId: aspirante.id,
                },
              }}},[_vm._v("Evaluar requerimientos")])],1)],2)}),0)])]),_c('b-modal',{staticClass:"modal-sq",attrs:{"id":"modal-seleccion-etapas","size":"lg","hide-footer":"","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function({ close }){return [_c('div',{staticClass:"px-3 py-2"},[_c('div',{staticClass:"d-flex justify-content-between mb-3",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 h4"},[_c('strong',[_vm._v("Requerimientos de selección")])]),_c('div',{staticClass:"btn btn-danger btn-sm btn-pm",on:{"click":function($event){return close()}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('b-row',{staticClass:"my-2"},[_c('b-col',[_c('BVForm',{attrs:{"schema":_vm.schemaNuevaEtapa,"callBackSubmit":_vm.agregarEtapa},scopedSlots:_vm._u([{key:"text-submit",fn:function(){return [_vm._v(" Agregar ")]},proxy:true}],null,true)})],1),_c('b-col',[_c('b-list-group',[_c('p',[_vm._v("Lista de etapas")]),(_vm.etapas.length == 0)?_c('div',[_c('b-list-group-item',[_c('p',{staticClass:"mb-0"},[_vm._v("No existen etapas")])])],1):_c('div',_vm._l((_vm.etapas),function(item,idx){return _c('b-list-group-item',{key:idx},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" Nombre: "+_vm._s(item.nombre)+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" Tipo de requerimiento: "+_vm._s(_vm.mapIdToTipo(item.tipoId))+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" Tipo de valoración: "),(item.esCuantificable == 1)?_c('span',[_vm._v(" Cuantificable ")]):_c('span',[_vm._v(" No cuantificable ")])])]),_c('b-button',{staticClass:"btn btn-pm",attrs:{"variant":"transparent","disabled":_vm.isLoadingBtnModal},on:{"click":function($event){$event.preventDefault();return _vm.deleteEtapa(idx)}}},[_c('i',{staticClass:"fas fa-solid fa-trash px-1 text-danger"})])],1)])}),1)])],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }