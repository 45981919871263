<script>
import BVForm from "@/components/BVForm/BVForm.vue";
import CardTable from "@/components/cardTable/CardTable.vue";
import { core } from "../../../config/pluginInit";
const schemaNuevaEtapa = [
  {
    name: "nombre",
    value: null,
    validations: "required",
    type: "text",
    label: "Nombre del requerimiento de la selección",
  },
  {
    name: "detalles",
    value: null,
    validations: "",
    type: "text",
    label: "Detalles del requerimiento",
  },
  {
    name: "tipoId",
    value: null,
    validations: "required",
    type: "select",
    label: "Tipo de requerimiento",
    options: [],
  },
  {
    name: "esCuantificable",
    value: null,
    validations: "required",
    type: "select",
    label: "¿El requerimiento es cuantificable?",
    options: [
      {
        id: 1,
        tipo: "Si",
      },
      {
        id: 0,
        tipo: "No",
      },
    ],
  },
];

export default {
  name: "ProcesoDeSeleccion",
  components: {
    BVForm,
    CardTable,
  },
  data() {
    return {
      aspirantes: [],
      etapas: [],
      documentosExistentes: [],
      nuevaEtapa: "",
      isLoadingBtnModal: false,
      schemaNuevaEtapa: schemaNuevaEtapa,
      resumenInCards: [
        {
          title: "Aprobados",
          value: () =>
            this.aspirantes.filter(
              (postulante) =>
                postulante.seleccionEvaluacion.estado == "Aprobado"
            ).length,
          iconName: "circle-check",
          color: "#16a275",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("Aprobado").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Pendientes",
          value: () =>
            this.aspirantes.filter(
              (postulante) =>
                postulante.seleccionEvaluacion.estado == "Pendiente"
            ).length,
          iconName: "clock",
          color: "#fe721c",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("Pendiente").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Rechazados",
          value: () =>
            this.aspirantes.filter(
              (postulante) =>
                postulante.seleccionEvaluacion.estado == "Rechazado"
            ).length,
          iconName: "ban",
          color: "rgba(223, 18, 46, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("Rechazado").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Total postulantes",
          value: () => this.aspirantes.length,
          iconName: "users",
          color: "rgba(71, 136, 255, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {},
          onDeactivate: () => {},
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    const response = await this.$store.getters.fetchGet({
      path: `Postulacion/postulantes/${this.$route.params.vacanteId}`,
    });
    const vacantes = await response.json();
    this.aspirantes = vacantes.filter(
      (aspirante) => aspirante.preseleccionEvaluacion.estado === "Aprobado"
    );
    await this.getEtapas();
    await this.getOptionsModal();
    await this.getDocumentos();

    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_seleccion")) {
      window.$("#datatable_seleccion").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_seleccion").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    getDocumentos() {
      this.$store.getters
        .fetchGet({
          path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${this.$route.params.vacanteId}`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error en el backend o DB");
        })
        .then((data) => {
          this.documentosExistentes = data;
          console.log("Documentos", data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getByNameAndPostulacion(nombre, postulacionId) {
      return this.documentosExistentes.find(
        (element) =>
          element.nombreIdEtapas == nombre &&
          element.postulacionId == postulacionId
      );
    },
    getEstadoDocumento(nombre, postulacionId) {
      if (this.getByNameAndPostulacion(nombre, postulacionId)) {
        return this.getByNameAndPostulacion(nombre, postulacionId).estado
          .estado;
      } else {
        return undefined;
      }
    },
    mapIdToTipo(id) {
      return this.schemaNuevaEtapa[2].options.find((tipo) => tipo.id === id)
        .tipo;
    },
    getOptionsModal() {
      this.$store.getters
        .fetchGet({ path: "ReclutamientoYSeleccion/Seleccion/etapas/tipos" })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al obtener los tipos de etapas");
        })
        .then((data) => {
          this.schemaNuevaEtapa[2].options = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal() {
      this.$bvModal.show("modal-seleccion-etapas");
    },
    agregarEtapa(self) {
      console.log(self.data);
      const data = {
        ...self.data,
        vacanteId: this.$route.params.vacanteId,
      };

      self.loader = true;

      this.$store.getters
        .fetchPost({
          path: "ReclutamientoYSeleccion/Seleccion/etapas",
          data: data,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al agregar la etapa");
        })
        .then((data) => {
          this.etapas.push(data);
          self.loader = false;
          self.schema.forEach((item) => {
            item.value = null;
          });
        })
        .catch((error) => {
          console.log(error);
          self.loader = false;
        });
    },
    deleteEtapa(idx) {
      const etapaId = this.etapas[idx].id;
      this.isLoadingBtnModal = true;
      this.$store.getters
        .fetchDelete({
          path: `ReclutamientoYSeleccion/Seleccion/etapas/${etapaId}`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al eliminar la etapa");
        })
        .then(() => {
          this.etapas.splice(idx, 1);
          this.isLoadingBtnModal = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingBtnModal = false;
        });
    },
    async getEtapas() {
      const response = await this.$store.getters.fetchGet({
        path: `ReclutamientoYSeleccion/Seleccion/etapas/${this.$route.params.vacanteId}`,
      });
      this.etapas = await response.json();
    },
    getBadgeStatus(value) {
      if (value == "Pendiente") {
        return {
          icon: "fas fa-clock",
          badge: "warning",
        };
      } else if (value == "Aprobado") {
        return {
          icon: "fas fa-check-circle",
          badge: "success",
        };
      } else if (value == "Rechazado") {
        return {
          icon: "fas fa-times-circle",
          badge: "danger",
        };
      }
    },
  },
};
</script>

<template>
  <HXContentCard
    title="Proceso de selección"
    :toBack="{ name: 'AdministradorDeVacantes' }"
  >
    <div class="row px-5 py-3">
      <div class="col-12 col-md-10">
        <CardTable :data="resumenInCards"></CardTable>
      </div>
      <div class="col-12 col-md">
        <vs-button class="h-100" @click="openModal">
          Administrador de elementos
        </vs-button>
      </div>
    </div>
    <div class="table-responsive mt-3 px-4">
      <table
        id="datatable_seleccion"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombres y apellidos</th>
            <th>Numero de documento</th>
            <th v-for="etapa in etapas" :key="etapa.id">
              {{ etapa.nombre }}
            </th>
            <th style="width: 10%">Estado final</th>

            <th>Evaluar candidatos</th>
          </tr>
        </thead>
        <tbody>
          <!-- <template v-if="aspirantes.length == 0">
            <tr>
              <td v-for="_ in Array(5 + etapas.length)" :key="_">Null</td>
            </tr>
          </template> -->
          <tr v-for="(aspirante, index) in aspirantes" :key="aspirante.id">
            <td>{{ index + 1 }}</td>
            <td>{{ aspirante.nombres + " " + aspirante.apellidos }}</td>
            <td>{{ aspirante.numeroDocumento }}</td>

            <td v-for="etapa in etapas" :key="etapa.id">
              <span
                v-if="
                  getEstadoDocumento(etapa.nombre, aspirante.id) == 'Aprobado'
                "
              >
                <i class="fas fa-check-circle text-success"></i>
              </span>
              <span
                v-else-if="
                  getEstadoDocumento(etapa.nombre, aspirante.id) == 'Rechazado'
                "
              >
                <i class="fas fa-times-circle text-danger"></i>
              </span>
              <span
                v-else-if="
                  getEstadoDocumento(etapa.nombre, aspirante.id) == 'Pendiente'
                "
              >
                <i class="fas fa-clock text-warning"></i>
              </span>
              <span v-else>
                <i class="fas fa-clock text-gray"></i>
              </span>
            </td>
            <td>
              <b-badge
                :variant="
                  getBadgeStatus(aspirante.seleccionEvaluacion.estado).badge
                "
              >
                <i
                  :class="
                    getBadgeStatus(aspirante.seleccionEvaluacion.estado).icon
                  "
                ></i>
                {{ aspirante.seleccionEvaluacion.estado }}
              </b-badge>
            </td>
            <td>
              <RouterLink
                class="btn btn-danger"
                :to="{
                  name: 'EvaluarDocumentosDeSeleccion',
                  params: {
                    vacanteId: $route.params.vacanteId,
                    postulacionId: aspirante.id,
                  },
                }"
                >Evaluar requerimientos</RouterLink
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      id="modal-seleccion-etapas"
      class="modal-sq"
      size="lg"
      hide-footer
      hide-header
    >
      <template #default="{ close }">
        <div class="px-3 py-2">
          <div class="d-flex justify-content-between mb-3" style="width: 100%">
            <p class="mb-0 h4"><strong>Requerimientos de selección</strong></p>
            <div @click="close()" class="btn btn-danger btn-sm btn-pm">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <b-row class="my-2">
            <b-col>
              <BVForm :schema="schemaNuevaEtapa" :callBackSubmit="agregarEtapa">
                <template #text-submit> Agregar </template>
              </BVForm>
            </b-col>
            <b-col>
              <b-list-group>
                <p>Lista de etapas</p>
                <div v-if="etapas.length == 0">
                  <b-list-group-item>
                    <p class="mb-0">No existen etapas</p>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item v-for="(item, idx) in etapas" :key="idx">
                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="mb-1">
                          Nombre:
                          {{ item.nombre }}
                        </p>
                        <p class="mb-1">
                          Tipo de requerimiento:
                          {{ mapIdToTipo(item.tipoId) }}
                        </p>
                        <p class="mb-1">
                          Tipo de valoración:
                          <span v-if="item.esCuantificable == 1">
                            Cuantificable
                          </span>
                          <span v-else> No cuantificable </span>
                        </p>
                      </div>
                      <b-button
                        @click.prevent="deleteEtapa(idx)"
                        class="btn btn-pm"
                        variant="transparent"
                        :disabled="isLoadingBtnModal"
                      >
                        <i class="fas fa-solid fa-trash px-1 text-danger"></i>
                      </b-button>
                    </div>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </HXContentCard>
</template>

<style scoped>
.btn-pm {
  position: relative;
  min-width: 20px;
  height: 20px;
  text-align: center;
  padding: 2px 5px;
  margin: 0px 2px;
  border: none;
  border-radius: 5px;
}

.btn-pm i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
